import { FinancialRecordLogTypeEnum, WithDrawTypeEnum } from '@/constants/assets'
import { FuturesTradeAccountTypeEnum, FuturesTradeModeEnum } from '@/constants/copy-trading/common'

/**
 * 资产 - 充币
 * @param id 币种 coinId
 */
export function getAssetsDepositPageRoutePath(id?: string) {
  let url = `/assets/main/deposit`
  if (id) url += `?id=${id}`
  return url
}

/**
 * 资产 - 法币充值
 */
export function getAssetsFiatPageRoutePath(id?: string) {
  let url = `/assets/main/fiat`
  if (id) url += `?id=${id}`
  return url
}

/**
 * 资产 - 提币
 * @param type 提币类型 WithDrawTypeEnum.platform 站内 WithDrawTypeEnum.blockchain 站外
 * @param id 币种 coinId
 */
export function getAssetsWithdrawPageRoutePath(type?: WithDrawTypeEnum, id?: string) {
  let url = `/assets/main/withdraw`
  if (type) url += `?type=${type}`
  if (id) {
    !type ? (url += `?id=${id}`) : (url += `&id=${id}`)
  }

  return url
}

/**
 * 资产 - 币种资产首页
 */
export function getAssetsMainPageRoutePath() {
  const url = `/assets/main`
  return url
}

/**
 * 资产 - c2c 资产首页
 */
export function getAssetsC2CPageRoutePath() {
  const url = `/assets/c2c`
  return url
}

/**
 * 资产 - 财务记录
 * @param type FinancialRecordLogTypeEnum
 * @param id 财务记录 id
 * @param subtype 子类型
 */
export function getAssetsHistoryPageRoutePath(
  type?: FinancialRecordLogTypeEnum,
  id?: string,
  subtype?: string,
  coinId?: string,
  coinName?: string
) {
  let url = `/assets/financial-record`
  if (type) url += `?type=${type}`
  if (id) {
    !type ? (url += `?id=${id}`) : (url += `&id=${id}`)
  }
  if (subtype) {
    !type && !id ? (url += `?subtype=${subtype}`) : (url += `&subtype=${subtype}`)
  }
  if (coinId) {
    !type && !id && !subtype ? (url += `?coinId=${coinId}`) : (url += `&coinId=${coinId}`)

    if (coinName) {
      url += `&coinName=${coinName}`
    }
  }
  return url
}

/**
 * 资产 - 合约组详情
 * @param groupId 合约组 id
 * @param assetAccountType 资产账户类型：合约/跟单/带单
 */
export function getFuturesDetailPageRoutePath(groupId?: string, assetAccountType?: FuturesTradeAccountTypeEnum) {
  let url = `/assets/futures/detail/${groupId}`
  switch (assetAccountType as FuturesTradeAccountTypeEnum) {
    case FuturesTradeAccountTypeEnum.takerAccount:
      url = `/assets/copy-trading/taker/detail/${groupId}`
      break
    case FuturesTradeAccountTypeEnum.followerAccount:
      url = `/assets/copy-trading/follower/detail/${groupId}`
      break
    default:
      url = `/assets/futures/detail/${groupId}`
  }
  return url
}

/**
 * 资产 - 历史仓位
 */
export function getFuturesHistoryPositionPageRoutePath(tradeMode?: FuturesTradeModeEnum) {
  if (tradeMode === FuturesTradeModeEnum.copyTrading) {
    return `/assets/takerOrder/history/width`
  }
  return `/assets/future/history/position`
}

/**
 * 带单跟单 - 历史仓位
 */
export function getFollowOrdersWithOrders() {
  const url = `/assets/takerOrder/history/width`
  return url
}

/**
 * 带单跟单 - 历史仓位
 */
export function getAssetsAccountHistoryRoutePath(groupName, groupId, accountType?: FuturesTradeAccountTypeEnum, type?) {
  let url = `/assets/futures/history/${groupName}/${groupId}`

  if (type) {
    url += `?type=${type}`
  }

  if (accountType) {
    // 如果 URL 中已经有查询参数，使用 '&' 连接
    url += url.includes('?') ? `&accountType=${accountType}` : `?accountType=${accountType}`
  }

  return url
}

/** 法币充值成功界面 */
export function getFaitRechargeSuccessPath() {
  return '/assets/recharge/fiat-deposit/recharge-success'
}

/** 资产 - 法币充提订单 */
export function getFiatOrdersPath() {
  return '/assets/fiat/order'
}
